<template>
	<v-container>
		<v-row>
			<v-col cols="3">
				<v-card>
					<v-select
						dense
						outlined
						hide-details
						label="technik"
						:items="technici"
						:menu-props="{ offsetY: true }"
						@change="selectChanged"
						v-model="technikSelect"
					>
					</v-select>
				</v-card>
			</v-col>

			<v-col cols="3">
				<v-card>
					<v-menu
						class="z-index"
						v-model="menu"
						:close-on-content-click="false"
						transition="scale-transition"
						offset-y
						max-width="290px"
						min-width="290px"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								dense
								outlined
								readonly
								hide-details
								clearable
								v-model="dateRangeText"
								label="Dátum"
								@click:clear="clearDate"
								prepend-inner-icon="mdi-calendar"
								v-bind="attrs"
								v-on="on"
							></v-text-field>
						</template>
						<v-date-picker
							v-model="dates"
							range
							no-title
							:first-day-of-week="1"
							locale="sk-sk"
						></v-date-picker>
					</v-menu>
				</v-card>
			</v-col>
		</v-row>
		<div id="main-content">
			<v-row>
				<v-col cols="12">
					<v-card height="100%">
						<v-card-title>
							Technické kontroly
						</v-card-title>
						<v-card-text>
							<Table :data="data_tk" />
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<v-card height="100%">
						<v-card-title>
							Emisné kontroly
						</v-card-title>
						<v-card-text>
							<Table :data="data_ek" />
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</div>
		<v-row>
			<v-col cols="12">
				<v-card height="100%">
					<v-card-actions>
						<v-spacer />
						<v-btn @click="printMe">Ulož tabuľky</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>

		<v-overlay :value="overlay">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
	</v-container>
</template>

<script>
import { mapGetters } from "vuex"
import axios from "axios"
import html2canvas from "html2canvas"

import { reviewTables } from "@/data/api"

import Table from "@/views/components/tables/Table.vue"

export default {
	name: "Graphs",

	components: {
		Table,
	},

	data() {
		return {
			data_tk: {},
			data_ek: {},

			technikSelect: "",

			menu: false,
			dates: [],
			old_dates: [],
			query_date: "",

			overlay: false,
		}
	},

	computed: {
		...mapGetters(["technici", "token", "isSuperAdmin"]),

		dateRangeText: {
			get() {
				const dates = this.dates
				dates.sort()
				return dates
					.map((date) => new Date(date).toLocaleDateString("sk-SK"))
					.join(" ~ ")
			},
			set() {
				return
			},
		},
	},

	methods: {
		async selectChanged() {
			this.overlay = true
			let data = {
				name: this.technikSelect,
				dates: this.query_date,
			}

			await axios
				.get(reviewTables(), {
					headers: {
						Authorization: "Token " + this.token,
					},

					params: data,
				})
				.then(({ data }) => {
					this.data_tk = data.tk
					this.data_ek = data.ek
				})
				.catch((error) => {
					if (error.response.status == 400) {
						this.data = {}
					}
				})

			this.overlay = false
		},

		updateByDate(dates) {
			if (dates.length == 2) {
				let date2 = new Date(dates[1])
				// GMT
				date2 = new Date(date2.getTime() - date2.getTimezoneOffset() * 60000)
				date2.setDate(date2.getDate() + 1)

				this.query_date = dates[0] + "," + date2.toISOString().slice(0, 10)
				this.selectChanged()
			} else if (dates.length == 1) {
				this.query_date = dates[0]
				this.selectChanged()
			}
		},

		clearDate() {
			this.dates = []
			this.old_dates = []
			this.query_date = ""

			this.selectChanged()
		},

		async printMe() {
			var canvas = document.getElementById("main-content")
			html2canvas(canvas, {
				scale: 3,
			}).then(function(canvas) {
				var a = document.createElement("a")
				a.href = canvas.toDataURL("image/png")
				a.download = "screenshot.png"
				a.click()
			})
		},
	},

	created() {
		this.selectChanged()
	},

	watch: {
		menu(value) {
			// if menu closed
			if (!value) {
				// if date changed
				if (JSON.stringify(this.old_dates) !== JSON.stringify(this.dates)) {
					this.old_dates = this.dates
					this.updateByDate(this.dates)
				}
			}
		},
	},
}
</script>
