<template>
	<v-simple-table class="table-border">
		<template v-slot:default>
			<thead>
				<tr>
					<th class="text-left table-head">
						Kat. zák.
					</th>
					<th class="text-left table-head">
						Počet kontrol
					</th>
					<th class="text-left table-head">
						Spôsobilé počet
					</th>
					<th class="text-left table-head">
						Dočasne počet
					</th>
					<th class="text-left table-head">
						Nespôsob počet
					</th>
					<th class="text-left table-head">
						Spôsobilé %
					</th>
					<th class="text-left table-head">
						Dočasne %
					</th>
					<th class="text-left table-head">
						Nespôsob %
					</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="type in Object.keys(data)" :key="type.id">
					<td class="table-col-type font-weight-bold">{{ type }}</td>
					<td class="table-cell">{{ data[type].count }}</td>
					<td class="table-cell">{{ data[type].sposobileCount }}</td>
					<td class="table-cell">{{ data[type].docasneCount }}</td>
					<td class="table-cell">{{ data[type].nesposobileCount }}</td>
					<td class="table-cell">{{ data[type].sposobilePerc }} %</td>
					<td class="table-cell">{{ data[type].docasnePerc }} %</td>
					<td class="table-cell">{{ data[type].nesposobilePerc }} %</td>
				</tr>
			</tbody>
		</template>
	</v-simple-table>
</template>

<script>
export default {
	name: "TablesTable",

	props: {
		data: {
			type: Object,
			required: true,
		},
	},
}
</script>

<style scoped>
.table-border {
	border: 1px solid black;
	border-right: 0px;
	/* border-collapse: collapse; */
}
.table-col-type {
	background-color: lightgrey !important;
	border-right: 1px solid black;
}
.table-head {
	color: black !important;
	/* background-color: cornflowerblue !important; */
	background-color: lightblue !important;
	border-right: 1px solid black;
}

.table-cell {
	border-right: 1px solid black;
}
</style>
